.Features .single-features:last-child::before {
  position: absolute;
  content: "";
  right: -72px;
  top: 60px;
  width: 118px;
  border-bottom: 0px dotted #1a76d1; /* Change this as needed */
}


.footer {
  color: #ffffff !important;
  background-color: #062051;
  font-size: 14px;
  position: relative;
}

.footer h1, .footer h2, .footer h3, .footer h4, .footer h5, .footer h6, .footer p, .footer a{
  color: #ffffff !important;
}

.footer .footer-about .logo img {
  max-height: auto !important;
}

.single-news {
  background-color: rgb(236, 234, 234);
  padding: 10px;
}

.news-head img {
  width: 100%;
}

.news-content {
  /* background-color: #ffffff; */
  margin-top: 20px;
}

.img-fluid {
  background-color: rgb(252, 237, 219);
  padding: 20px;
  border-radius: 20px;
}

.services-img {
  border-radius: 20px;
  width: 100%;
}

.fg-red {
  color: rgb(168, 25, 25);
}